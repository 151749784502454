import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import metamask from './assets/img/MetaMask_Fox.svg.png'
import walletconnect from './assets/img/a5169900-c66c-11e9-8592-33c7334dfd6d.png'
import Close from "./assets/svg/Close";
import coinbase from '../src/assets/img/coin.png'

class ConnectWalletModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggleMuffinModal}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x' onClick={this.props.toggle}
                         style={{top: this.props.mobile && -4, right: this.props.mobile && -6}}>
                        <Close fill={'#B772CC'}/>
                    </div>
                    <div className='col-ac'>
                        <div className='col-ac font-3 splash-subtext2-mobile mb-40'>
                            <div style={{fontSize: this.props.mobile && 14}}>
                                Connect your wallet
                            </div>
                        </div>
                        <div className='row-ac-jc flex-wrap' style={{width: this.props.mobile ? 200 : 800}}>
                            <div className='wallet-option' onClick={() => this.props.triggerProvider("metamask")}
                                 style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                                <img src={metamask}
                                     style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                     className='mt-1'/>
                                <p className='mv-8 font-1' style={{fontSize: this.props.mobile ? 16 : 24}}>Metamask</p>
                                <p className='font-2 mt-0 mobile-subname text-center'
                                   style={{fontSize: this.props.mobile && 10}}>Connect to your Metamask Wallet</p>
                            </div>
                            <div className='wallet-option' onClick={() => this.props.triggerProvider("walletconnect")}
                                 style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                                <img src={walletconnect}
                                     style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                     className='mt-1'/>
                                <p className='mv-8 font-1'
                                   style={{fontSize: this.props.mobile ? 16 : 24}}>WalletConnect</p>
                                <p className='font-2 mt-0 mobile-subname text-center'
                                   style={{fontSize: this.props.mobile && 10}}>Scan with WalletConnect to connect</p>
                            </div>
                            <div className='wallet-option' onClick={() => this.props.triggerProvider("walletlink")}
                                 style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                                <img src={coinbase}
                                     style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                     className='mt-1 br-8 mb-12'/>
                                <p className='mv-8 font-1' style={{fontSize: this.props.mobile ? 16 : 24}}>Coinbase
                                    Wallet</p>
                                <p className='font-2 mt-0 mobile-subname text-center'
                                   style={{fontSize: this.props.mobile && 10}}>Connect to your Coinbase Wallet</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default ConnectWalletModal
