import React, {Component} from "react";
import sparkle from "../../assets/img/Sparkle.png";
import splashlogo from "../../assets/img/splashlogo.png";
import splashbg from "../../assets/img/splash-bg.png";
import MintButton from "../../MintButton";

class Splash extends Component {
    state = {
        step: 'not-connected', // not-connected, connected, success,
        batchSize: 1,
        total: 10000,
        minted: 234
    }

    render() {
        const pctMinted = (this.state.minted / this.state.total) * 100
        return (
            <section className='splash-bg relative' style={{paddingTop: 200}}>
                <img src={sparkle} className='sparkle5'/>
                <div className='relative col-ac-jc' style={{zIndex: 1}}>
                    <div className='relative col-ac-jc'>
                        <img src={sparkle} className='sparkle1'/>
                        <img src={sparkle} className='sparkle2'/>
                        <img src={sparkle} className='sparkle3'/>
                        <img src={sparkle} className='sparkle4'/>
                        <img src={splashlogo} className='w-70p'/>
                        {/*<SplashLogo/>*/}
                    </div>
                    <div className='mb-64 relative col-ac' style={{marginTop: -50, zIndex: 11, height: 270, width:660}}>
                        <MintButton/>
                    </div>
                    <div className='font-1 splash-subtext relative'>
                        <img src={sparkle} className='sparkle5'/>
                        <img src={sparkle} className='sparkle6'/>
                        <img src={sparkle} className='sparkle7'/>
                        <img src={sparkle} className='sparkle8'/>
                        <img src={sparkle} className='sparkle9'/>
                        <img src={sparkle} className='sparkle10'/>
                        <img src={sparkle} className='sparkle11'/>
                        {/*<p>Minting Soon</p>*/}
                        {/*<p>*/}
                        {/*    0/10,000*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*    MINTED*/}
                        {/*</p>*/}
                    </div>
                    <div className='text-center font-3 splash-subtext2 mt-80'>
                        <p>
                            2,222
                        </p>
                        <p>
                            Uniquely Degenerate Gators
                        </p>
                    </div>
                </div>
                <img src={splashbg} className='w-100p' style={{marginBottom: -130}}/>
            </section>
        )
    }
}

export default Splash
