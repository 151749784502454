import React, {Component} from "react";
import theteam from "../../assets/img/theteam.png";
import greensticker from "../../assets/img/green-round-subtle-peeling.png";
import image7 from "../../assets/img/new-3.png";
import {TEAM} from "../../consts/copy";
import creambreak from "../../assets/img/cream-break.png";


class Team extends Component {
    render() {
        return (
            <>
                <section className='col-ac pv-100 pb-80 cream-bg'>
                    <div className='pt-64 relative'>
                        <img src={theteam} style={{width: 520}} className='mb-32'/>
                        <div className='absolute' style={{top: -310, left: -440, zIndex: 90}}>
                            <div className='relative col-ac'>
                                <img src={greensticker}
                                     style={{marginBottom: -50, width: 100, zIndex: 11, marginRight: 10}}/>
                                <img src={image7} style={{width: 310, transform: 'rotate(0deg)'}}/>
                            </div>
                        </div>
                    </div>
                    <div className='row-ac-jc mt-64 relative' style={{zIndex: 1, flexWrap: 'wrap'}}>
                        {TEAM.map(member =>
                            <div key={member.name} className='text-center mh-32 mb-24'>
                                <img src={member.image} style={{width: 240}}/>
                                <p className='member-name font-1'>{member.name}</p>
                                <p className='member-title font-3'>{member.title}</p>
                            </div>
                        )}
                    </div>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={creambreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default Team
