import * as React from "react";

const Close = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M18 6L6 18" stroke={props.fill || "#FFFFFF"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 6L18 18" stroke={props.fill || "#FFFFFF"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


);

export default Close;
