import React, {Component} from "react";
import plungebefore from '../../assets/img/plungebefore.png'
import after from '../../assets/img/Image 7@2x.png'

import DEAD_GATOR from '../../assets/img/dead-gator.png'
import ANGEL_GATOR from "../../assets/img/01ANGEL.png"

function wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

class PlungeStep3 extends Component {
    state = {
        begin: false,
        shrink: false,
        darken: false,
        showButton: false,
        switchPicture: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.startAnimation !== this.props.startAnimation) {
            this.setState({begin: true})
            wait(1200).then(() => {
                    this.setState({darken: true})
                }
            )
            wait(1700).then(() => {
                    this.setState({shrink: true})
                }
            )
            wait(4600).then(() => {
                    this.setState({showButton: true})
                }
            )
            wait(4900).then(() => {
                    this.setState({switchPicture: true})
                }
            )
        }
    }

    beforeAnimation = () => {

    }

    render() {

        const deadImage = this.props.selectedGator.id % 2 === 0 ? ANGEL_GATOR : DEAD_GATOR;
        if (this.props.selectedGator === {}) {
            return <div></div>
        }
        const evolvedImage = this.props.selectedGator?.survived ? "https://ipfs.io/ipfs/" + this.props.selectedGator.image?.slice(5, -5) + (this.props.selectedGator.tier + 1) + ".png" : deadImage
        const currentImage = "https://ipfs.io/ipfs/" + this.props.selectedGator.image?.slice(5)
        return (
            <div className='w-100p col-ac-jc relative' style={{height: '100vh'}}>
                <div className={`plunge plunge-animation-cover ${this.state.darken && 'cover-animated'}`}/>
                <img
                    src={this.state.switchPicture ? evolvedImage : currentImage}
                    className={`before-shake opacity-transition ${this.state.shrink && 'shrink'}`}
                    style={{opacity: this.state.begin ? 1 : 0, zIndex: 1000, width:this.props.mobile ? '65%' : '40%'}}/>
                <button className='font-3 absolute opacity-transition'
                        style={{opacity: this.state.showButton ? 1 : 0, bottom: 60, zIndex: 1000}}>
                    <a style={{textDecoration: "none"}} target="_blank"
                       href={"https://opensea.io/collection/seshworld"}>View on Opensea</a>
                </button>
                <div className={`absolute  ${this.state.darken && 'plunge-animation-cover-dark'}`}/>
                <div className='absolute salmon' style={{top: '10%', right: '20%', zIndex: 1002}}>
                    <p className='font-4 pointer'
                       onClick={() => this.props.scrollTo('start')}
                       style={{opacity: this.state.showButton ? 1 : 0}}
                    >
                        Back To Surface
                    </p>
                </div>
                <img
                    src={evolvedImage}
                    className={`before-shake opacity-transition ${this.state.shrink && 'shrink'}`}
                    style={{position: "absolute", display: "none"}}/>
            </div>
        )
    }
}


export default PlungeStep3;
