import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import Close from "../assets/svg/Close";
import prereveal from '../assets/img/prereveal.png'
import Spinner from "../assets/svg/Spinner";

class SelectGatorModal extends Component {
    state = {
        selectedGator: null
    }

    render() {
        const disabled = (!this.state.selectedGator || (this.state.selectedGator?.name === "The Departed") || this.state.selectedGator?.tier > 1)
        console.log("GATORS", this.props.gators, this.state.selectedGator?.name === "The Departed")
        return (
            <Modal
                isOpen={this.props.isOpen}
                contentLabel="Example Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x'
                         style={{top: this.props.mobile && -4, right: this.props.mobile && -6}}
                         onClick={this.props.toggle}>
                        <Close fill={'#55346E'}/>
                    </div>
                    <div className='col-ac' style={{width: this.props.mobile ? 250 : '80vw'}}>
                        <div className='mb-20 mt-8 font-3 splash-subtext2-mobile'>
                            Select your Gator
                        </div>
                        <p className='font-2 mb-20'>
                            Choose wisely. You can only take one gator to the plunge at a time.
                        </p>
                        {this.props.loading ?
                            <div className='col-ac-jc font-3 mt-32'>
                                <p className='mb-12'>Gators Loading</p>
                                <Spinner sizez={20}/>
                            </div>
                            : this.props.gators.length === 0 ?
                                <div className='row-ac-jc font-3 mt-32'>
                                    <p>No gators found.</p>
                                </div>
                                :
                                <div className='row-ac-jb flex-wrap br-12' style={{
                                    maxHeight: '50vh',
                                    overflow: 'scroll',
                                    border: this.props.gators.length > 6 && '1px solid #643D7220'
                                }}>
                                    {this.props.gators.map(gator => {
                                            const selected = gator.id === this.state.selectedGator?.id
                                            return (
                                                <div
                                                    className={this.props.mobile ? 'm-4 mb-12 col-plain' : 'mh-12 mv-8 col-plain'}>
                                                    <img
                                                        onClick={() => {
                                                            this.setState({selectedGator: gator})
                                                        }}
                                                        src={"https://ipfs.io/ipfs/" + gator.image.slice(5)}
                                                        className={selected ? 'select-item-selected' : 'select-item'}
                                                        style={{width: this.props.mobile ? 80 : 200}}/>
                                                    <div className='col-plain ph-4 mt-8'>
                                                        <div className='mb-4 bold font-2'
                                                             style={{fontSize: this.props.mobile && 12}}>
                                                            Seshworld {gator.id}
                                                        </div>
                                                        <div style={{fontSize: this.props.mobile && 10}}>
                                                            Tier {gator.tier + 1}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                        }
                        <div className='row-ac-jc mt-52'>
                            <button onClick={this.props.toggle} className='font-3 mr-12 pink-button'>
                                Cancel
                            </button>
                            <button disabled={disabled}
                                    onClick={() => this.props.selectGator(this.state.selectedGator)}
                                    style={{opacity: disabled && .5}} className='font-3 ml-12'>
                                Confirm
                            </button>
                        </div>

                        {/*<p className='font-2 mt-20 mb-0 text-center'*/}
                        {/*   style={{width: !this.props.mobile && 450, fontSize: this.props.mobile && 14}}>*/}
                        {/*    Check your wallet to confirm. Welcome to the sesh. Art to be revealed on Sunday.*/}
                        {/*</p>*/}
                        {/*<button className='font-3 mt-20' style={{fontSize: this.props.mobile && 16}}>*/}
                        {/*    <a style={{textDecoration: "none"}} target="_blank" href={"https://opensea.io/collection/seshworld"}>View on Opensea</a>*/}
                        {/*</button>*/}
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default SelectGatorModal
