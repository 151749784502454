import React, {Component} from "react";
import balllogo from "../assets/img/ball_logo.png";
import Instagram from "../assets/svg/Instagram";
import Discord from "../assets/svg/Discord";
import thelore from "../assets/img/thelore.png";
import {LORE_1, LORE_2} from "../consts/copy";
import admitone from "../assets/img/admitone.png";
import team1pic from "../assets/img/new-1.png";
import pinkbreak from "../assets/img/pink-break.png";


class LoreMobile extends Component {
    render() {
        return (
            <>
                <section className='col-ac text-center bg-pink pt-100 relative'
                         style={{zIndex: 0}}>
                    <div className='mt-100'>
                        <img src={thelore} className='w-70p'/>
                    </div>
                    <div className='w-80p relative mb-80' style={{zIndex: 11}}>
                        <p className='font-2 mb-12 mt-40'>
                            {LORE_1}
                        </p>
                        <p className='font-2 mb-100'>
                            {LORE_2}
                        </p>
                    </div>
                </section>
                <div className='relative w-100p col-ac-jc'>
                    <div className='absolute' style={{bottom: -130, zIndex: 10}}>
                        <div className='relative col-ac'>
                            <img src={admitone} style={{marginBottom: -60, width: 140, zIndex: 11}}/>
                            <img src={team1pic} style={{width: 200}}/>
                        </div>
                    </div>
                </div>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={pinkbreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default LoreMobile
