import '../styles/App.css';
import '../styles/Formatting.css'
import React, {Component} from "react";
import roadmap from '../assets/img/roadmap.png'
import Navbar from "../web-components/main/Navbar";
import Splash from "../web-components/main/Splash";
import Lore from "../web-components/main/Lore";
import Roadmap from "../web-components/main/Roadmap";
import Plunge from "../web-components/main/Plunge";
import Team from "../web-components/main/Team";
import Footer from "../web-components/main/Footer";
import LoreMobile from "../mobile-components/LoreMobile";
import RoadmapMobile from "../mobile-components/RoadmapMobile";
import PlungeMobile from "../mobile-components/PlungeMobile";
import TeamMobile from "../mobile-components/TeamMobile";
import FooterMobile from "../mobile-components/FooterMobile";
import SplashMobile from "../mobile-components/SplashMobile";
import NavbarMobile from "../mobile-components/NavbarMobile";
import NavMenuMobile from "../mobile-components/NavMenuMobile";

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobile: false,
            tablet: false,
            mobileMenu: false
        }
        this.homeRef = React.createRef();
        this.loreRef = React.createRef();
        this.roadmapRef = React.createRef();
        this.plungeRef = React.createRef();
        this.teamRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200)
        });
    }


    scrollTo = (route) => {
        if (route === 'home') {
            this.homeRef.current.scrollIntoView({behavior: 'smooth'})
            this.setState({mobileMenu: false})
        }
        if (route === 'lore') {
            this.loreRef.current.scrollIntoView({behavior: 'smooth'})
            this.setState({mobileMenu: false})
        }
        if (route === 'roadmap') {
            this.roadmapRef.current.scrollIntoView({behavior: 'smooth'})
            this.setState({mobileMenu: false})
        }
        if (route === 'plunge') {
            this.plungeRef.current.scrollIntoView({behavior: 'smooth'})
            this.setState({mobileMenu: false})
        }
        if (route === 'team') {
            this.teamRef.current.scrollIntoView({behavior: 'smooth'})
            this.setState({mobileMenu: false})
        }
    }


    render() {
        if (this.state.mobile) {
            return (
                <div className='relative'>
                    <NavMenuMobile
                        onClickClose={() => this.setState({mobileMenu: false})}
                        scrollTo={this.scrollTo}
                        mobileMenu={this.state.mobileMenu}
                    />
                    <NavbarMobile
                        onClickBall={() => this.scrollTo('home')}
                        onClickMenu={() => this.setState({mobileMenu: true})}
                    />
                    <div ref={this.homeRef}>
                        <SplashMobile/>
                    </div>
                    <div ref={this.loreRef}>
                        <LoreMobile/>
                    </div>
                    <div ref={this.roadmapRef}>
                        <RoadmapMobile/>
                    </div>
                    <div ref={this.plungeRef}>
                        <PlungeMobile/>
                    </div>
                    <div ref={this.teamRef}>
                        <TeamMobile/>
                    </div>
                    <FooterMobile/>
                </div>
            )
        }
        return (
            <div className='w-100p' style={{overflowX: 'hidden'}}>
                <Navbar/>
                <Splash/>
                <Lore tablet={this.state.tablet}/>
                <Roadmap tablet={this.state.tablet}/>
                <Plunge tablet={this.state.tablet}/>
                <Team/>
                <Footer/>
            </div>
        )
    }
}


export default Main;
