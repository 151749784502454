import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import Close from "../assets/svg/Close";
import prereveal from '../assets/img/prereveal.png'

class MintSuccessModal extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.props.toggleMuffinModal}
                // style={customStyles}
                contentLabel="Example Modal"
                className="Modal2"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x'
                         style={{top: this.props.mobile && -4, right: this.props.mobile && -6}}
                         onClick={this.props.toggle}>
                        <Close fill={'#FFFDEB'}/>
                    </div>
                    <div className='col-ac' style={{width: this.props.mobile && 250}}>
                        <div className='mb-20 mt-8 font-3 splash-subtext2-mobile'>
                            Mint Complete!
                        </div>
                        <img src={prereveal} className='mint-item' style={{width: this.props.mobile ? 160 : 450}}/>
                        <p className='font-2 mt-20 mb-0 text-center'
                           style={{width: !this.props.mobile && 450, fontSize: this.props.mobile && 14}}>
                            Check your wallet to confirm. Welcome to the sesh. Art to be revealed on Sunday.
                        </p>
                        <button className='font-3 mt-20' style={{fontSize: this.props.mobile && 16}}>
                            <a style={{textDecoration: "none"}} target="_blank" href={"https://opensea.io/collection/seshworld"}>View on Opensea</a>
                        </button>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default MintSuccessModal
