import * as React from "react";

const FooterDiscord = (props) => (
    <svg id="Group_28" data-name="Group 28" xmlns="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/1999/xlink" width="105.165" height="80.159" viewBox="0 0 105.165 80.159">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_21" data-name="Rectangle 21" width="105.165" height="80.159" fill="none"/>
            </clipPath>
        </defs>
        <g id="Group_27" data-name="Group 27" clip-path="url(#clip-path)">
            <path id="Path_34" data-name="Path 34"
                  d="M89.026,6.645A86.725,86.725,0,0,0,67.62.006a.326.326,0,0,0-.344.163A60.4,60.4,0,0,0,64.61,5.644a80.047,80.047,0,0,0-24.042,0A55.452,55.452,0,0,0,37.861.169a.338.338,0,0,0-.344-.163A86.5,86.5,0,0,0,16.112,6.645a.3.3,0,0,0-.141.121C2.337,27.135-1.4,47,.434,66.624a.362.362,0,0,0,.137.246,87.213,87.213,0,0,0,26.26,13.274.341.341,0,0,0,.369-.121,62.288,62.288,0,0,0,5.372-8.738.333.333,0,0,0-.182-.463,57.432,57.432,0,0,1-8.2-3.91.338.338,0,0,1-.033-.559c.552-.413,1.1-.842,1.629-1.277a.326.326,0,0,1,.34-.046,62.193,62.193,0,0,0,52.852,0,.325.325,0,0,1,.344.042c.526.434,1.078.868,1.633,1.281a.337.337,0,0,1-.029.559,53.919,53.919,0,0,1-8.208,3.906.336.336,0,0,0-.178.467A70.03,70.03,0,0,0,77.9,80.019a.334.334,0,0,0,.369.125,86.917,86.917,0,0,0,26.3-13.274.336.336,0,0,0,.136-.242C106.9,43.944,101.039,24.239,89.163,6.77a.268.268,0,0,0-.137-.125M35.142,54.677c-5.182,0-9.451-4.757-9.451-10.6s4.187-10.6,9.451-10.6c5.306,0,9.534,4.8,9.451,10.6,0,5.842-4.186,10.6-9.451,10.6m34.944,0c-5.182,0-9.451-4.757-9.451-10.6s4.187-10.6,9.451-10.6c5.306,0,9.534,4.8,9.451,10.6,0,5.842-4.145,10.6-9.451,10.6"
                  transform="translate(0 0)" fill="#fff6de"/>
        </g>
    </svg>
);

export default FooterDiscord;
