import React, {Component} from "react";
import theplunge from "../assets/img/theplunge.png";
import {PLUNGE_1, PLUNGE_2, POWDER_LABEL} from "../consts/copy";
import powder from "../assets/img/Powder.png";
import mintbreak from "../assets/img/mint-break.png";
import {Link} from "react-router-dom";


class PlungeMobile extends Component {
    render() {
        return (
            <>
                <section className='col-ac text-center bg-mint pt-100 pb-100 relative'>
                    <div className='pt-100'>
                        <img src={theplunge} className='w-70p'/>
                    </div>
                    <div className='w-70p' style={{position: 'relative', zIndex: 1}}>
                        <div className='font-2'>
                            <div className='mt-80 mb-64'>
                                <p className='bold mb-24'>
                                    {PLUNGE_1}
                                </p>
                                <p className='bold'>
                                    {PLUNGE_2}
                                </p>
                                <Link to='/theplunge' style={{textDecoration: 'none', fontSize: 36}}>
                                    <button className='font-3 mt-32'>
                                        Take the Plunge
                                    </button>
                                </Link>
                            </div>
                            <img src={powder} className='w-100p'/>
                            <p className='mt-24 bold font-3'>
                                {POWDER_LABEL}
                            </p>
                        </div>
                    </div>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={mintbreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default PlungeMobile
