import React, {Component} from "react";
import shaman from '../../assets/img/shaman.png'
import {PLUNGE_TEXT_1, PLUNGE_TEXT_1a, PLUNGE_TEXT_2, PLUNGE_TEXT_3, PLUNGE_TEXT_3a} from "../../consts/copy";
import SelectGatorModal from "../SelectGatorModal";
import PlungeFailedModal from "../PlungeFailedModal";

function wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}

class PlungeStep2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initiated: false,
            questionOpacity: 1,
            replyOpacity: 0,
            warningOpacity: 0,
            loadingOpacity: 0,
            buttonOpacity: 0,
            failedModalOpen: false,
        }
        this.ref1 = React.createRef();
    }

    initiatePlunge = () => {
        this.setState({initiated: true, questionOpacity: 0})
        wait(1700).then(() => {
                this.setState({replyOpacity: 1})
            }
        )
        wait(2700).then(() => {
                this.setState({warningOpacity: 1})
            }
        )
        wait(3700).then(() => {
                this.setState({buttonOpacity: 1})
            }
        )
    }

    beginLoading = async () => {
        this.setState({initiated: true, replyOpacity: 0, loadingOpacity: 1})
        const {died, error} = await this.props.plunge(this.props.tokenID)

        if (error) {
            this.setState({failedModalOpen: true});
        } else {
            const newGator = this.props.editGatorStatus(!died);

            wait(2700).then(() => {
                    this.setState({loadingOpacity: 0});
                    this.goNext()
                }
            )
        }
    }

    goNext = () => {
        this.props.scrollTo('step3')
        wait(1000).then(() => {
                this.props.startAnimation()
            }
        )
    }


    render() {
        return (
            <div className='w-100p col-ac font-4' style={{minHeight: '100vh'}}>
                <div className={this.props.mobile ? 'w-80p col-ac relative' : 'relative'}>
                    <img src={shaman} style={{width: this.props.mobile ? '100%' : 610}} className='mt-100'/>
                    <div className='absolute' style={{top: 350}} ref={this.ref1}/>
                </div>
                <div className='col-ac relative'>
                    <div className='absolute col-ac-jc opacity-transition'
                         style={{zIndex: 1, opacity: this.state.questionOpacity}}>
                        <p className={this.props.mobile ? 'cream mv-60 text-center w-80p' : 'text-center cream mv-60'}
                           style={{fontSize: this.props.mobile && 14}}>
                            {this.props.selectedGator?.tier === 0 ? PLUNGE_TEXT_1 : PLUNGE_TEXT_1a}
                        </p>
                        <div className='row-ac cream'>
                            <p className='mh-60 pointer hover white-on-h' onClick={this.initiatePlunge}>
                                _ YES
                            </p>
                            <p className='mh-60 pointer hover white-on-h' onClick={() => this.props.scrollTo('start')}>
                                X NO
                            </p>
                        </div>
                    </div>
                    <div className='col-ac opacity-transition'
                         style={{width: !this.props.mobile ? 860 : '70%', opacity: this.state.replyOpacity, zIndex: 0}}>
                        <p className='cream mv-60 mb-32 w-70p text-center' style={{fontSize: this.props.mobile && 14}}>
                            {PLUNGE_TEXT_2}
                        </p>
                        <p className='salmon text-center opacity-transition'
                           style={{opacity: this.state.warningOpacity, fontSize: this.props.mobile ? 8 : 14}}
                        >
                            {this.props.selectedGator?.tier === 0 ? PLUNGE_TEXT_3 : PLUNGE_TEXT_3a} with <span
                            style={{fontWeight: 600}}>{this.props.selectedGator?.id}</span>?
                        </p>
                        <div
                            className={this.props.mobile ? 'row-ac mv-100 mt-12 opacity-transition' : 'row-ac mv-100 mt-32 opacity-transition'}
                            style={{opacity: this.state.buttonOpacity}}>
                            <button className='font-3 mr-60 pink-button' onClick={this.beginLoading}>
                                yes
                            </button>
                            <button className='font-3 ml-60' onClick={() => {
                                this.props.scrollTo('start')
                            }}>
                                no
                            </button>
                        </div>
                    </div>
                    <div className='absolute col-ac-jc opacity-transition'
                         style={{zIndex: this.state.loadingOpacity, opacity: this.state.loadingOpacity}}>
                        <p className={this.props.mobile ? 'cream mv-60 text-center w-80p' : 'text-center cream mv-60'}
                           style={{fontSize: this.props.mobile && 14}}>
                            Plunge commencing... please wait. It can take up to a minute to decide your fate.
                        </p>
                        <div className="spinner" style={{width: 32, height: 32}}/>
                    </div>
                </div>
                {/*<PlungeFailedModal*/}
                {/*    isOpen={this.state.failedModalOpen}*/}
                {/*    mobile={this.props.mobile}*/}
                {/*    toggle={() => this.setState({failedModalOpen: false})}*/}
                {/*    goBack={() => {*/}
                {/*        this.setState({failedModalOpen: false})*/}
                {/*        wait(1000).then(() => {*/}
                {/*                this.setState({loadingOpacity: 0});*/}
                {/*                this.props.scrollTo('start')*/}
                {/*                wait(1000).then(() => {*/}
                {/*                    window.location.reload();*/}
                {/*                })*/}
                {/*            }*/}
                {/*        )*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
        )
    }

}


export default PlungeStep2;
