import team1pic from '../assets/img/Image 9.png'
import team2pic from '../assets/img/Image 7.png'
import team3pic from '../assets/img/Image 8.png'
import team4pic from '../assets/img/Image 3.png'

// export const LORE = 'There was once a great indigenous gator clan that inhabited a fertile corner of the Yucatan Peninsula known as'
// export const LORE_BOLD = " SeshWorld"
// export const LORE_2 = ". These gators were revered as gods by the Mayans for their ability to party for weeks on end. I mean, these reptiles were sick in the head. They would rip ayahuasca and coca leaves with their frog and lizard boys and talk shit into the wee hours of the night, sometimes for weeks on end, about neighboring snake cucks. But times had changed. The golden era was slipping away with the dawn of a new human civilization  - the Aztecs."
// export const LORE_3 = 'The Aztecs began to demolish the outskirts of SeshWorld to accommodate more farms and all-inclusive resorts for WOATs trying to party in coastal Mexico. The brave gator generations of old had spawned a younger, and more impressionable, generation brainwashed by mainstream gator media. The clan became soft in their partying and weak in their commitment to the sesh. The Aztecs began buying up Class-A coastal gator real estate far too easily. Before long, they owned the entire province of SeshWorld - then Kevin arrived.'
// export const LORE_4 = 'Kevin, known around the clan simply as “Kev”, was quickly climbing the political gator ranks in SeshWorld. He was notably outspoken on geopolitical issues from a young age. After a few charismatic speeches and sickening public seshes over Instagram Live, he found himself at the helm of a populist movement calling for a return to the sesh roots often sung about by the elder gator storytellers.'
// export const LORE_5 = 'One warm summer evening, Kev ventured to Tenochtitlan to negotiate Sehstopia’s sovereignty with the Aztecs, nothing on his person except his trusty bag. The city was brimming with life and dope tribal house music. After absolutely launching all night, he passed out in a sacrificial chamber and woke up with his pants around his ankles and a black eye. He was very hungover, but couldn’t return home until he had fulfilled his quest. He dragged his mangled gator body into the highest holy temple to speak with the war chief of the Aztec Empire - Todd. Todd and his lieutenants ridiculed Kev for wearing his hangover and called him a pussy ass bitch. They lamented that past gator generations in SeshWorld could party for weeks on end with no sleep. Kev shrugged off the massive amount of narcotics coursing through his system. He rose up and grabbed the Aztec’s sacred orb, splitting his soul and being into 2,222 pieces. Kev’s life had ended, but the quest to reclaim the disputed indigenous gator lands from the Aztecs was about to begin. Will the next generation restore integrity and honor to SeshWorld? Your journey awaits you.'


export const LORE_1 = "It was a meteoric rise the likes of which had never been seen before. Within six years of winning a grassroots populist movement to chair his local school board, Kev won the United States’ presidency. High on paint thinner one night in the Oval Office, Kev decided to send a lewd DM to Vladimir Putin III’s daughter. The nuclear fallout was devastating. Most biomes were completely wiped off the face of the earth. Creatures that survived were mutated beyond recognition, twisted into monstrosities and gross shells of their former selves. However, some life forms were more resilient. Some life forms even developed a little hankering for drugs…"
export const LORE_2 = "Deep in Central Florida, an abandoned amusement park whirred along. Rides rolled by and music played over the loudspeakers eerily for an audience of zero. The park had somehow survived the nuclear war and fall of humanity and remained in operation via solar power. One day the park was discovered by earth’s newest apex predators, a clan of degenerate gators. Corrupted and warped by radiation, these gators were left with nothing but a crippling urge to sesh with their friends, whom they absolutely adored. They renovated the park, branded it SeshWorld, and opened it to whatever surviving creatures wanted to join them. They implemented just one law: once you enter, you may never leave. Care to join them? Act fast, the park is only selling 2,222 tickets…"

export const ROADMAP_1 = "Owning a Seshworld gator grants you access to all perks of Seshworld's Roadmap"

export const MERCH_1 = "• 20% off all items on www.thursdaylines.co in perpetuity"
export const MERCH_2 = "• Merch Drops exclusively for NFT-holders with crowd-sourced input from the community"


export const PERK_1 = "• Special @SeshWorld badge in 'The Swamp' plus private channels with other owners to brainstorm ideas for improving and growing the community"
export const PERK_2 = "Access to @Thursday.lines and @gatortails close friends' stories on Instagram"
export const PERK_3 = "Private access to exclusive SeshWorld DJ sets mixed by some of your favorite artists"

export const CHARITY = "A portion of all proceeds will be donated to charity. The exact charity is TBD."

export const PLUNGE_1 = "Would you rather be a coward your entire life living in your shit shell of a body or take the plunge risking it all for the possibility of true greatness."
export const PLUNGE_2 = "Chances are it’s not hard to improve your terrible physique at the moment, however, there’s always a what-if when ingesting mystery substances."
export const POWDER_LABEL = "Mystery Powder"


export const TEAM = [
    {
        image: team1pic,
        name: "Brickolas Cage",
        title: "Creator",
    },
    {
        image: team2pic,
        name: "The Todd Father",
        title: "Creator",
    },
    {
        image: team3pic,
        name: "Woat Jesus",
        title: "Creator",
    },
    {
        image: team4pic,
        name: "Camereto",
        title: "Artist",
    }
]

export const JOIN_DISCORD = "Join our discord"
export const VIEW_COLLECTION = "View collection"

export const PLUNGE_TEXT_1 = '“Hello little one… are you ready to take The Plunge?”'

export const PLUNGE_TEXT_2 = '- “Yes, for I do not fear death, only a life full of fear and regret”'

export const PLUNGE_TEXT_3 = 'There is a 10% chance you will be banished to the afterlife. Your soul will survive but you will be trapped with others that failed The Plunge. There is a 90% chance you will evolve into something much greater and more exciting than yourself. Would you like to proceed'

export const PLUNGE_TEXT_1a = '“Back again? I am glad. Ready to take The Plunge once more and seek the glory you deserve?”'

export const PLUNGE_TEXT_3a = 'There is a 70% chance you will be banished to the afterlife. Your soul will survive but you will be trapped with others that failed The Plunge. There is a 30% chance you will evolve into an elite specimen reserved for only the true greats. Would you like to proceed'
