import React, {Component} from "react";

class Spinner extends Component {
    render() {
        return (
            <div className="spinner" style={{width: this.props.size, height: this.props.size}}/>
        );
    }
}

export default Spinner
