import * as React from "react";

const Instagram = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="35.008" height="35" viewBox="0 0 35.008 35">
        <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
              d="M17.5,10.764a8.974,8.974,0,1,0,8.974,8.974A8.959,8.959,0,0,0,17.5,10.764Zm0,14.807a5.834,5.834,0,1,1,5.834-5.834A5.845,5.845,0,0,1,17.5,25.572ZM28.936,10.4A2.093,2.093,0,1,1,26.843,8.3,2.088,2.088,0,0,1,28.936,10.4Zm5.943,2.124c-.133-2.8-.773-5.287-2.827-7.333S27.522,2.5,24.719,2.361c-2.89-.164-11.551-.164-14.44,0C7.482,2.493,5,3.134,2.945,5.18S.258,9.71.118,12.513c-.164,2.89-.164,11.551,0,14.44.133,2.8.773,5.287,2.827,7.333s4.53,2.687,7.333,2.827c2.89.164,11.551.164,14.44,0,2.8-.133,5.287-.773,7.333-2.827s2.687-4.53,2.827-7.333c.164-2.89.164-11.543,0-14.433ZM31.146,30.054a5.906,5.906,0,0,1-3.327,3.327c-2.3.914-7.771.7-10.317.7s-8.021.2-10.317-.7a5.906,5.906,0,0,1-3.327-3.327c-.914-2.3-.7-7.771-.7-10.317s-.2-8.021.7-10.317A5.906,5.906,0,0,1,7.186,6.094c2.3-.914,7.771-.7,10.317-.7s8.021-.2,10.317.7a5.906,5.906,0,0,1,3.327,3.327c.914,2.3.7,7.771.7,10.317S32.06,27.758,31.146,30.054Z"
              transform="translate(0.005 -2.238)" fill="#fff6de"/>
    </svg>
);

export default Instagram;
