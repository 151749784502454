import React, {Component} from "react";
import balllogo from "../../assets/img/ball_logo.png";
import Instagram from "../../assets/svg/Instagram";
import Discord from "../../assets/svg/Discord";
import {Link} from "react-router-dom";


class Navbar extends Component {
    render() {
        return (
            <div className='row-ac-jb absolute w-100p' style={{zIndex: 100, height: 80}}>
                <img src={balllogo} style={{width: 42}} className='pl-80'/>
                <div className='row-ac pr-80'>
                    <Link to='/theplunge' style={{textDecoration: 'none', fontSize: 24}} className='font-1 mr-40 white-on-h-text'>
                        The Plunge
                    </Link>
                    <div className='mr-40'>
                        <a href='https://www.instagram.com/seshworld.io/' target='_blank'>
                            <Instagram/>
                        </a>
                    </div>
                    <div>
                        <a href='https://discord.gg/XRDkMRK7gC' target='_blank'>
                            <Discord/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar
