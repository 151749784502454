import React, {Component} from "react";
import balllogo from "../assets/img/ball_logo.png";
import Instagram from "../assets/svg/Instagram";
import Discord from "../assets/svg/Discord";
import theteam from "../assets/img/theteam.png";
import {TEAM} from "../consts/copy";
import creambreak from "../assets/img/cream-break.png";


class TeamMobile extends Component {
    render() {
        return (
            <>
                <section className='col-ac pv-100 pb-80 cream-bg'>
                    <div className='pt-64 relative col-ac-jc'>
                        <img src={theteam} className='w-70p'/>
                    </div>
                    <div className='row-ac-jc mt-64 relative' style={{zIndex: 1, flexWrap: 'wrap'}}>
                        {TEAM.map(member =>
                            <div key={member.name} className='text-center mh-32 mb-24'>
                                <img src={member.image} style={{width: '90%'}}/>
                                <p className='member-name font-1'>{member.name}</p>
                                <p className='member-title font-3'>{member.title}</p>
                            </div>
                        )}
                    </div>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={creambreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default TeamMobile
