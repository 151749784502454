import React, {Component} from "react";
import balllogo from "../assets/img/ball_logo.png";
import Instagram from "../assets/svg/Instagram";
import Discord from "../assets/svg/Discord";
import {JOIN_DISCORD, VIEW_COLLECTION} from "../consts/copy";
import FooterDiscord from "../assets/svg/FooterDiscord";
import footerlogo from "../assets/img/footerlogo.png";


class FooterMobile extends Component {
    render() {
        return (
            <>
                <div className='bg-light-purple pt-64'>
                    <section className='col-ac-jc bg-light-purple cream footer-titles-mobile font-1 pv-92 relative'>
                        <div style={{flex: 1}} className='col-ac-jc'>
                            <p className='mb-24'>
                                {JOIN_DISCORD}
                            </p>
                            <div style={{height: 60}} className='col-ac-jc mt-24'>
                                <a href='https://discord.gg/XRDkMRK7gC' target='_blank'>
                                    <FooterDiscord/>
                                </a>
                            </div>
                        </div>
                        <div
                            className='cream-bg footer-divider-mobile mv-80'
                        />
                        <div style={{flex: 1}} className='col-ac-jc'>
                            <p className='mb-24'>
                                {VIEW_COLLECTION}
                            </p>
                            <div style={{height: 60}} className='col-ac-jc'>
                                <button className='font-3'>
                                    <a style={{textDecoration: "none"}} target="_blank"
                                       href={"https://opensea.io/collection/seshworld"}>
                                        Opensea
                                    </a>
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
                <section className='col-ac-jc bg-dark-purple pv-20'>
                    <img src={footerlogo} className='w-50p'/>
                </section>
            </>
        )
    }
}

export default FooterMobile
