import React, {Component} from "react";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import WalletLink from 'walletlink'
import ConnectWalletModal from "./ConnectWalletModal";
import {ethers} from "ethers"
import {
    ABI,
    CONTRACT_ADDRESS,
    MINT_PRICE_WEI,
    PUBLIC_MINT_FUNCTION_SELECTOR,
    MAINNET_CHAIN_ID, RPC_URL, INFURAID
} from "./utils/consts"
import MintSuccessModal from "./web-components/MintSuccessModal";
import Spinner from "./assets/svg/Spinner";

// import MintSuccessModal from "./MintSuccessModal";

// Initialize WalletLink
const walletLink = new WalletLink({
    appName: "SeshWorld",
    appLogoUrl: "SESH",
})


class MintButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: "",
            connected: false,
            mode: "walletlink",
            connectModalVisble: false,
            batchSize: 1,
            mintSuccess: false,
            pctMinted: 0,
            numMinted: 0,
            total: '2,222',
            loading: false
        }
        this.provider = new WalletConnectProvider({
            infuraId: INFURAID, // Required
        });
        this.ethersProvider = undefined
        this.contract = new ethers.Contract(CONTRACT_ADDRESS, ABI)
        this.linkProvider = walletLink.makeWeb3Provider(RPC_URL, 1)
        this.defaultProvider = ethers.getDefaultProvider()
    }

    getTotalSupply = async () => {
        console.log("SUPPLY")
        const readContract = this.contract.connect(this.defaultProvider)
        let totalSupply = await readContract.totalSupply()
        console.log("SUPPLY", totalSupply)
        this.setState({numMinted: parseInt(totalSupply), pctMinted: parseInt(parseInt(totalSupply) / 2222 *100)})
        return parseInt(totalSupply)
    }


    triggerProvider = async (mode) => {
        if (mode === "metamask") {
            if (window.ethereum) {
                console.log("CONNECTED TO ETHEREUM")
                this.provider = window.ethereum;
                const address = await this.provider.request({method: 'eth_requestAccounts'})
                this.setState({
                    connected: true,
                    mode: "ethereum",
                    walletAddress: ethers.utils.getAddress(address[0].toString())
                })
                // METAMASK CHECK CONNECTION
            } else {
                alert("No Metamask connection found! Please use wallet connect or try inside the metamask app.")
            }
        } else if (mode === "walletlink") {
            const connect = await this.linkProvider.enable();
            this.provider = this.linkProvider;
            this.setState({
                connected: true,
                walletAddress: ethers.utils.getAddress(connect[0].toString())
            })
        } else {
            try {
                const connect = await this.provider.enable();
                this.setState({
                    connected: true,
                    walletAddress: ethers.utils.getAddress(connect[0].toString())
                })

            } catch (err) {
                console.log("ERROR", err)
            }
        }

        const chainId = await this.provider.request({method: "eth_chainId"})
        if (chainId !== MAINNET_CHAIN_ID) {
            await this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: MAINNET_CHAIN_ID}]
            });
        }

        // init ethers provider using the web3 provider we just connected to
        this.ethersProvider = new ethers.providers.Web3Provider(this.provider)
    }

    onSuccess = () => {
        this.props.successMint();
    }

    publicMintNow = async () => {
        const encodedParams = ethers.utils.defaultAbiCoder.encode(
            ["uint256"], [this.state.batchSize]
        )
        const calldata = PUBLIC_MINT_FUNCTION_SELECTOR + encodedParams.slice(2)
        const txHash = await this.provider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.state.walletAddress,
                to: CONTRACT_ADDRESS,
                value: '0x' + ((MINT_PRICE_WEI) * this.state.batchSize).toString(16),
                data: calldata,
            }]
        })
        this.setState({loading: true})
        await this.ethersProvider.waitForTransaction(txHash)
        console.log("MINED")
        this.getTotalSupply();
        this.setState({mintSuccess: true, loading: false})
    }

    componentDidMount = () => {
        this.getTotalSupply()
    }

    counterUp = () => {
        this.setState({numToMint: this.state.numToMint + 1})
    }
    counterDown = () => {
        this.setState({numToMint: this.state.numToMint - 1})
    }

    render() {
        const minusDisabled = this.state.numToMint === 1
        if (!this.state.connected) {
            return <>
                <div className='col-ac'>
                    <p className='splash-subtext font-1 mb-20' style={{fontSize: this.props.mobile && 24}}>Minting
                        Now</p>
                    <button className='font-3 splash-button' style={{fontSize: this.props.mobile && 20}}
                            onClick={() => this.setState({connectModalVisible: true})}>
                        Connect Wallet
                    </button>
                </div>
                <ConnectWalletModal
                    mobile={this.props.mobile}
                    isOpen={this.state.connectModalVisible}
                    toggle={() => this.setState({connectModalVisible: false})}
                    triggerProvider={this.triggerProvider}
                />
            </>
        } else {
            return (
                <div>
                    <div className='col-ac' style={{width: this.props.mobile && 300}}>
                        <div className='w-100p mb-32'>
                            <p className='text-center mb-12 font-1'>{this.state.pctMinted}% Minted</p>
                            <div className='w-100p br-8' style={{backgroundColor: '#F2D5FF'}}>
                                <div className='br-8'
                                     style={{width: `${this.state.pctMinted}%`, backgroundColor: '#6B4876', height: 8}}>
                                </div>
                            </div>
                            <div className='row-ac-jb mt-4'>
                                <p className='font-1'
                                   style={{opacity: .5, marginLeft: `${this.state.pctMinted - 2.5}%`}}>
                                    {this.state.numMinted}
                                </p>
                                <p className='font-1' style={{opacity: .5}}>
                                    {this.state.total}
                                </p>
                            </div>
                        </div>
                        <div className='row-ac'>
                            <div className='col-ac mr-32 w-50p'>
                                <p className='splash-subtext3 font-1 mb-4' style={{
                                    fontSize: this.props.mobile && 20,
                                    marginBottom: this.props.mobile && 24
                                }}>Select Quantity</p>
                                <div className='row-ac splash-subtext font-1'>
                                    <div className='pointer'
                                         onClick={() => this.setState({batchSize: this.state.batchSize - 1})}
                                         style={{
                                             opacity: this.state.batchSize > 1 ? 1 : .5,
                                             fontSize: this.props.mobile && 24
                                         }}>
                                        -
                                    </div>
                                    <p className={this.props.mobile ? 'font-3 mh-20' : 'font-3 mh-40'}
                                       style={{fontSize: this.props.mobile && 28}}>
                                        {this.state.batchSize}
                                    </p>
                                    <div className='pointer' style={{fontSize: this.props.mobile && 24}}
                                         onClick={() => this.setState({
                                             batchSize: this.state.batchSize + 1,
                                         })}>
                                        +
                                    </div>
                                </div>
                            </div>
                            <div className='col-ac ml-32 w-50p'>
                                <p className='splash-subtext3 font-1 mb-4'>Price</p>
                                <div className='row-ac splash-subtext font-1'>
                                    <p className='font-3 mh-40' style={{fontSize: this.props.mobile && 28}}>
                                        {(this.state.batchSize * .02).toFixed(2)}<span
                                        className='splash-subtext2-mobile'>eth</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button className='font-3 splash-button ph-32 mt-32' style={{width: 200}}
                                onClick={this.publicMintNow}>
                            {this.state.loading ?
                                <Spinner sizez={20}/>
                                :
                                <p>
                                    Mint {this.state.batchSize > 1 && this.state.batchSize}
                                </p>
                            }
                        </button>
                        <p className='font-4 mt-20 text-center'>
                            <span className='font-3'>Connected Wallet:</span>{this.props.mobile && <br/>} <span
                            style={{fontWeight: 'bold'}}>{this.state.walletAddress}</span>
                        </p>
                    </div>
                    <MintSuccessModal
                        isOpen={this.state.mintSuccess}
                        mobile={this.props.mobile}
                        toggle={() => this.setState({mintSuccess: false})}
                    />
                </div>
            )
        }
    }
}


export default MintButton
