import '../styles/App.css';
import '../styles/Formatting.css'
import React, {Component} from "react";
import PlungeStart from "../web-components/theplunge/PlungeStart";
import PlungeStep2 from "../web-components/theplunge/PlungeStep2";
import PlungeStep3 from "../web-components/theplunge/PlungeStep3";
import prereveal from '../assets/img/prereveal.png'
import {wait} from "@testing-library/user-event/dist/utils";
import {ethers} from "ethers"
import {PLUNGE_FUNCTION_SELECTOR, CONTRACT_ADDRESS, ABI, INFURAID, RPC_URL, MAINNET_CHAIN_ID} from '../utils/consts';


import gator1 from '../assets/img/Image 3.png'
import gator2 from '../assets/img/Image 7.png'
import gator3 from '../assets/img/Image 8.png'
import WalletConnectProvider from '@walletconnect/ethereum-provider';
import WalletLink from "walletlink";
import {Link} from "react-router-dom";


const walletLink = new WalletLink({
    appName: "SeshWorld",
    appLogoUrl: "SESH",
})

// gators: [
//                 {
//                     id: 'Seshworld #234',
//                     tier: 1,
//                     img: gator1,
//                     evolvedImg: gator2,
//                 },
//                 {
//                     id: 'Seshworld #3456',
//                     tier: 2,
//                     img: gator2,
//                     evolvedImg: gator3,
//                 },
//                 {
//                     id: 'Seshworld #3543',
//                     tier: 1,
//                     img: gator3,
//                     evolvedImg: gator1,
//                 },
//                 {
//                     id: 'Seshworld #64',
//                     tier: 1,
//                     img: prereveal,
//                     evolvedImg: gator1,
//                 },
//                 {
//                     id: 'Seshworld #765',
//                     tier: 1,
//                     img: prereveal,
//                     evolvedImg: gator1,
//                 },

class ThePlunge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: "",
            walletConnected: false,
            mobile: false,
            tablet: false,
            startAnimation: false,
            selectedGator: null,
            gators: [],
            images: [],
            connectModalVisible: false,
            loading: true,
        }
        this.startRef = React.createRef();
        this.step2Ref = React.createRef();
        this.step3Ref = React.createRef();
        this.provider = new WalletConnectProvider({
            infuraId: INFURAID, // Required
        });
        this.ethersProvider = undefined
        this.contract = new ethers.Contract(CONTRACT_ADDRESS, ABI)
        this.linkProvider = walletLink.makeWeb3Provider(RPC_URL, 1)
        this.defaultProvider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/e7063582d3d3402eb17e2a866605d12e")
    }

    toggleModal = (newState) => {
        this.setState({connectModalVisible: newState})
    }

    componentDidMount = async () => {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }

    fetchWalletInfo = async () => {

        const tokens = await this.getTokenIdsInWallet();
        console.log("TOKENS", tokens)
        const promises = []
        const gatorTierPromises = [];
        const isLockedPromises = [];
        for (const token of tokens) {
            promises.push(this.getGatorInfo(token));
            gatorTierPromises.push(this.getGatorTier(token));
        }
        const gators = await Promise.all(promises);
        const gatorTiers = await Promise.all(gatorTierPromises);

        const gatorPromise = [];
        for (const gator of gators) {
            gatorPromise.push(fetch("https://ipfs.io/ipfs/" + gator.slice(5)));
        }


        const gatorInfo = await Promise.all(gatorPromise);

        const responses = [];
        for (const gator of gatorInfo) {
            responses.push(gator.json())
        }

        const gatorMetadata = await Promise.all(responses)

        for (let i = 0; i < gatorMetadata.length; i++) {
            console.log("ADDING", parseInt(gatorTiers[i]))
            gatorMetadata[i].tier = parseInt(gatorTiers[i]);
            gatorMetadata[i].id = parseInt(tokens[i])
            gatorMetadata[i].survived = true
        }
        console.log("METAT", gatorMetadata)
        this.setState({gators: gatorMetadata, loading: false})
    }

    checkIsLocked = (token) => {

    }

    triggerProvider = async (mode) => {
        if (mode === "metamask") {
            if (window.ethereum) {
                console.log("CONNECTED TO ETHEREUM")
                this.provider = window.ethereum;
                const address = await this.provider.request({method: 'eth_requestAccounts'})
                this.setState({
                    connectModalVisible: false,
                    connected: true,
                    mode: "ethereum",
                    walletAddress: ethers.utils.getAddress(address[0].toString())
                })
                // METAMASK CHECK CONNECTION
                this.fetchWalletInfo()
            } else {
                alert("No Metamask connection found! Please use wallet connect or try inside the metamask app.")
            }
        } else if (mode === "walletlink") {
            const connect = await this.linkProvider.enable();
            this.provider = this.linkProvider;
            this.setState({
                connectModalVisible: false,
                connected: true,
                walletAddress: ethers.utils.getAddress(connect[0].toString())
            })
            this.fetchWalletInfo()
        } else {
            try {
                const connect = await this.provider.enable();
                this.setState({
                    connectModalVisible: false,
                    connected: true,
                    walletAddress: ethers.utils.getAddress(connect[0].toString())
                })
                this.fetchWalletInfo()

            } catch (err) {
                console.log("ERROR", err)
            }
        }

        const chainId = await this.provider.request({method: "eth_chainId"})
        if (chainId !== MAINNET_CHAIN_ID) {
            await this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: MAINNET_CHAIN_ID}]
            });
        }

        // init ethers provider using the web3 provider we just connected to
        this.ethersProvider = new ethers.providers.Web3Provider(this.provider)
    }

    // uses public provider, doesn't need wallet
    getTokenIdsInWallet = async () => {
        const readContract = this.contract.connect(this.defaultProvider)
        let gatorIds = await readContract.walletOfOwner(this.state.walletAddress)
        const formattedIds = [];
        for (const id of gatorIds) {
            formattedIds.push(id);
        }
        return gatorIds
    }

    // pull specific gator metadata
    getGatorInfo = async (tokenID) => {
        const readContract = this.contract.connect(this.defaultProvider)
        return readContract.tokenURI(tokenID);
    }

    // pull specific gator metadata
    getGatorTier = async (tokenID) => {
        const readContract = this.contract.connect(this.defaultProvider)
        return readContract.tokenLevels(tokenID);
    }

    isLocked = async (tokenID) => {
        const readContract = this.contract.connect(this.defaultProvider)
        return readContract.isLocked(tokenID);
    }


    plunge = async (tokenId) => {
        const encodedParams = ethers.utils.defaultAbiCoder.encode(
            ["uint256"], [tokenId]
        )
        const calldata = PLUNGE_FUNCTION_SELECTOR + encodedParams.slice(2)
        const txHash = await this.provider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.state.walletAddress,
                to: CONTRACT_ADDRESS,
                value: '0x0',
                gas: '0x14C08',
                gasLimit: '0x14C08',
                data: calldata,
            }]
        })
        await this.ethersProvider.waitForTransaction(txHash)
        // const transaction = await this.ethersProvider.getTransaction("0x7d047ca87b2974f29fbec1a7be50eaae56c97ef6d08444c380cccb13f52ace59");
        // try {
        //     const error = await this.ethersProvider.call(transaction);

        // } catch {
        //     return {died: false, error: true};
        // }
        const newGator = await this.isLocked(tokenId);
        return {died: newGator, error: false};
    }


    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200)
        });
    }

    startAnimation = () => {
        this.setState({startAnimation: true})
        console.log('clicked')
    }

    editGatorStatus = (newStatus) => {
        const gator = {...this.state.selectedGator}
        gator.survived = newStatus;
        this.setState({selectedGator: gator});
    }

    scrollTo = (route) => {
        if (route === 'start') {
            console.log("SCROLLING")
            this.startRef.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'step2') {
            this.step2Ref.current.scrollIntoView({behavior: 'smooth'})
        }
        if (route === 'step3') {
            this.step3Ref.current.scrollIntoView({behavior: 'smooth'})
        }
    }

    selectGator = (gator) => {
        this.setState({selectedGator: gator})
        wait(500).then(() =>
            this.scrollTo('step2')
        );
        console.log(gator)
    }

    render() {
        return (
            <div className='w-100p plunge-bg relative' style={{overflow: 'hidden', height: '100vh'}}>
                <div>
                    <Link to='/'
                          style={{textDecoration: 'none', fontSize: 26, left: this.state.mobile ? '10%' : 80, top: 40}}
                          className={'font-3 absolute cream white-on-h-text'}>
                        Back
                    </Link>
                </div>
                <div ref={this.startRef}>
                    <PlungeStart
                        mobile={this.state.mobile}
                        scrollTo={this.scrollTo}
                        selectGator={this.selectGator}
                        selectedGator={this.state.selectedGator}
                        gators={this.state.gators}
                        walletConnected={this.state.connected}
                        walletAddress={this.state.walletAddress}
                        triggerProvider={this.triggerProvider}
                        connectModalVisible={this.state.connectModalVisible}
                        toggleModal={this.toggleModal}
                        loading={this.state.loading}
                    />
                </div>
                <div ref={this.step2Ref}>
                    <PlungeStep2
                        tokenID={this.state.selectedGator?.id}
                        mobile={this.state.mobile}
                        scrollTo={this.scrollTo}
                        startAnimation={this.startAnimation}
                        selectedGator={this.state.selectedGator}
                        editGatorStatus={this.editGatorStatus}
                        plunge={this.plunge}
                    />
                </div>
                <div ref={this.step3Ref}>
                    <PlungeStep3
                        mobile={this.state.mobile}
                        scrollTo={this.scrollTo}
                        selectedGator={this.state.selectedGator || {}}
                        startAnimation={this.state.startAnimation}
                    />
                </div>
            </div>
        )
    }
}


export default ThePlunge;
