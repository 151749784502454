import * as React from "react";

const Instagram = (props) => (
    <svg id="Group_40" data-name="Group 40" xmlns="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/1999/xlink" width="45.918" height="35" viewBox="0 0 45.918 35">
        <g id="Group_27" data-name="Group 27" clip-path="url(#clip-path)">
            <path id="Path_34" data-name="Path 34"
                  d="M38.871,2.9A37.867,37.867,0,0,0,29.525,0a.142.142,0,0,0-.15.071,26.37,26.37,0,0,0-1.164,2.39,34.951,34.951,0,0,0-10.5,0A24.212,24.212,0,0,0,16.531.074.147.147,0,0,0,16.381,0,37.768,37.768,0,0,0,7.035,2.9a.131.131,0,0,0-.062.053A38.753,38.753,0,0,0,.189,29.09a.158.158,0,0,0,.06.107,38.08,38.08,0,0,0,11.466,5.8.149.149,0,0,0,.161-.053,27.2,27.2,0,0,0,2.346-3.815.145.145,0,0,0-.079-.2,25.077,25.077,0,0,1-3.582-1.707.147.147,0,0,1-.014-.244c.241-.18.482-.368.711-.557a.142.142,0,0,1,.148-.02,27.155,27.155,0,0,0,23.077,0,.142.142,0,0,1,.15.018c.23.19.471.379.713.559a.147.147,0,0,1-.013.244,23.543,23.543,0,0,1-3.584,1.706.147.147,0,0,0-.078.2,30.578,30.578,0,0,0,2.344,3.814.146.146,0,0,0,.161.055A37.95,37.95,0,0,0,45.66,29.2a.147.147,0,0,0,.06-.106A38.5,38.5,0,0,0,38.931,2.956a.117.117,0,0,0-.06-.055M15.344,23.874a4.413,4.413,0,0,1-4.127-4.628,4.389,4.389,0,0,1,4.127-4.628,4.365,4.365,0,0,1,4.127,4.628,4.389,4.389,0,0,1-4.127,4.628m15.258,0a4.413,4.413,0,0,1-4.127-4.628A4.389,4.389,0,0,1,30.6,14.618a4.365,4.365,0,0,1,4.127,4.628A4.377,4.377,0,0,1,30.6,23.874"
                  transform="translate(0.001 0)" fill="#fff6de"/>
        </g>
    </svg>

);

export default Instagram;
