import React, {Component} from "react";
import tent from '../../assets/img/tent.png'
import plungetitle from '../../assets/img/plungetitle.png'
import SelectGatorModal from "../SelectGatorModal";
import ConnectWalletModal from "../../ConnectWalletModal";

class PlungeStart extends Component {
    state = {
        selectModalOpen: false,
    }

    render() {
        return (
            <div className='w-100p col-ac-jc' style={{height: '100vh'}}>
                <img src={plungetitle} style={{marginBottom: -80, width: 650}}
                     className={this.props.mobile && 'w-80p'}/>
                <img src={tent} style={{width: 1000}} className={this.props.mobile && 'w-80p mt-52'}/>
                {!this.props.walletConnected ?
                    <div>
                        <button className={this.props.mobile ? 'font-3 mt-60' : 'font-3'} onClick={()=>this.props.toggleModal(true)}>
                            connect wallet
                        </button>
                        <ConnectWalletModal
                            mobile={this.props.mobile}
                            isOpen={this.props.connectModalVisible}
                            toggle={() => this.props.toggleModal(false)}
                            triggerProvider={this.props.triggerProvider}
                        />
                    </div>
                    : this.props.selectedGator ?
                        <button className={this.props.mobile ? 'font-3 mt-60' : 'font-3'}
                                onClick={() => this.props.scrollTo('step2')}>
                            enter
                        </button>
                        :
                        <button className={this.props.mobile ? 'font-3 mt-60' : 'font-3'}
                                onClick={() => this.setState({selectModalOpen: true})}>
                            select gator
                        </button>
                }
                <SelectGatorModal
                    isOpen={this.state.selectModalOpen}
                    gators={this.props.gators}
                    mobile={this.props.mobile}
                    selectGator={(gator) => {
                        this.props.selectGator(gator);
                        this.setState({selectModalOpen: false})

                    }}
                    loading={this.props.loading}
                    toggle={() => this.setState({selectModalOpen: false})}
                />
            </div>
        )
    }

}


export default PlungeStart;
