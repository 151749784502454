import React, {Component} from "react";
import roadmap from "../../assets/img/roadmap.png";
import {CHARITY, MERCH_1, MERCH_2, PERK_1, PERK_2, PERK_3, ROADMAP_1} from "../../consts/copy";
import creambreak from "../../assets/img/cream-break.png";


class Roadmap extends Component {
    render() {
        return (
            <>
                <section className='col-ac text-center cream-bg font-2 pv-100 relative'>
                    <div className='mt-100'>
                        <img src={roadmap} style={{width: 520}} className='mb-32'/>
                    </div>
                    <div className={this.props.tablet ? 'w-60p relative' : 'w-40p relative'} style={{zIndex: 1}}>
                        <p className='mt-24 bold'>{ROADMAP_1}</p>
                        <div className='mv-24'>
                            <p className='bold mb-12'>
                                Merch
                            </p>
                            <p className='mb-8'>
                                {MERCH_1}
                            </p>
                            <p>
                                {MERCH_2}
                            </p>
                        </div>
                        <div className='mb-24 relative'>
                            <p className='bold mb-12'>
                                Heightened Discord Access
                            </p>
                            <p className='mb-8'>
                                {PERK_1}
                            </p>
                            <p className='mb-8 mt-32 bold'>
                                {PERK_2}
                            </p>
                            <p className='bold'>
                                {PERK_3}
                            </p>
                        </div>
                        <p className='bold'>
                            {CHARITY}
                        </p>
                    </div>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={creambreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default Roadmap
