import React, {Component} from "react";
import balllogo from "../assets/img/ball_logo.png";
import Hamburger from "../assets/svg/Hamburger";


class NavbarMobile extends Component {
    render() {
        return (
            <div className='row-ac-jb w-100p' style={{
                zIndex: 1000,
                height: 60,
                position: 'fixed',
                backgroundColor: '#643D72AE',
                backdropFilter: 'blur(4px)'
            }}>
                <img src={balllogo} style={{width: 42}} className='pl-20'
                     onClick={this.props.onClickBall}/>
                <div style={{zIndex: 9999}} onClick={this.props.onClickMenu}>
                    <Hamburger width={42} height={42} viewBox={'0 0 24 24'}/>
                </div>
            </div>
        )
    }
}

export default NavbarMobile
