import React, {Component} from "react";
import {JOIN_DISCORD, VIEW_COLLECTION} from "../../consts/copy";
import FooterDiscord from "../../assets/svg/FooterDiscord";
import footerlogo from "../../assets/img/footerlogo.png";


class Footer extends Component {
    render() {
        return (
            <>
                <div className='bg-light-purple pt-64'>
                    <section className='row-ac-jc bg-light-purple cream footer-titles font-1 pv-92 relative'>
                        <div style={{flex: 1}} className='col-ac-jc'>
                            <p className='mb-24'>
                                {JOIN_DISCORD}
                            </p>
                            <div style={{height: 60}} className='col-ac-jc'>
                                <a href='https://discord.gg/XRDkMRK7gC' target='_blank'>
                                    <FooterDiscord/>
                                </a>
                            </div>
                        </div>
                        <div
                            className='cream-bg footer-divider'
                        />
                        <div style={{flex: 1}} className='col-ac-jc'>
                            <p className='mb-24'>
                                {VIEW_COLLECTION}
                            </p>
                            <div style={{height: 60}} className='col-ac-jc'>
                                <button className='font-3'>
                                    <a style={{textDecoration: "none"}} target="_blank"
                                       href={"https://opensea.io/collection/seshworld"}>Opensea</a>
                                </button>
                            </div>
                        </div>
                    </section>
                </div>

                <section className='row-ac-jc bg-dark-purple pv-16'>
                    <img src={footerlogo} style={{width: 200}}/>
                </section>
            </>
        )
    }
}

export default Footer
