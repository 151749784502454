import React, {Component} from "react";
import thelore from "../../assets/img/thelore.png";
import {LORE_1, LORE_2} from "../../consts/copy";
import admitone from "../../assets/img/admitone.png";
import team1pic from "../../assets/img/new-1.png";
import pinkbreak from "../../assets/img/pink-break.png";


class Lore extends Component {
    render() {
        return (
            <>
                <section className='col-ac text-center bg-pink pt-100'>
                    <div className='mt-100'>
                        <img src={thelore} style={{width: 520}} className='mb-32'/>
                    </div>
                    <div className={this.props.tablet ? 'w-60p relative' : 'w-40p relative'} style={{zIndex: 11}}>
                        {/*<p className='font-2 mt-40 mb-12'>*/}
                        {/*    {LORE}*/}
                        {/*    <span className='bold font-1' style={{fontSize: 20}}>*/}
                        {/*    {LORE_BOLD}*/}
                        {/*</span>*/}
                        {/*    {LORE_2}*/}
                        {/*</p>*/}
                        {/*<p className='font-2 mb-12'>*/}
                        {/*    {LORE_3}*/}
                        {/*</p>*/}
                        {/*<p className='font-2 mb-12'>*/}
                        {/*    {LORE_4}*/}
                        {/*</p>*/}
                        {/*<p className='font-2 mb-100'>*/}
                        {/*    {LORE_5}*/}
                        {/*</p>*/}
                        <p className='font-2 mb-12 mt-40'>
                            {LORE_1}
                        </p>
                        <p className='font-2 mb-100'>
                            {LORE_2}
                        </p>
                        <div className='absolute' style={{bottom: -200, left: -400, zIndex: 10}}>
                            <div className='relative col-ac'>
                                <img src={admitone} style={{marginBottom: -80, width: 250, zIndex: 11}}/>
                                <img src={team1pic} style={{width:320}}/>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={pinkbreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default Lore
