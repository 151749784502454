import React, {Component} from "react";
import Instagram from "../assets/svg/Instagram";
import Discord from "../assets/svg/Discord";
import Close from "../assets/svg/Close";
import splashlogo from "../assets/img/splashlogo.png";
import {Link} from "react-router-dom";


class NavMenuMobile extends Component {
    render() {
        return (
            <div style={{
                height: this.props.mobileMenu ? '100vh' : 0,
                backgroundColor: '#643D72CE',
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                zIndex: 9999,
                transition: 'height 150ms ease',
                backdropFilter: 'blur(5px)',
            }}>
                {this.props.mobileMenu &&
                <>
                    <div className='row-ac-je absolute w-100p' style={{zIndex: 100, height: 60}}>
                        <div style={{zIndex: 9999, paddingRight: 10}}
                             onClick={this.props.onClickClose}>
                            <Close width={32} height={32} viewBox={'0 0 24 24'}/>
                        </div>
                    </div>
                    <div className='col-ac-jc' style={{height: '95vh'}}>
                        <img src={splashlogo} className='w-50p mb-40'
                             onClick={() => this.props.scrollTo('home')}
                             style={{zIndex: 999, position: 'relative'}}/>
                        <p className='font-1 mv-20 mobile-nav-item' onClick={() => this.props.scrollTo('lore')}>
                            The Lore
                        </p>
                        <p className='font-1 mv-20 mobile-nav-item' onClick={() => this.props.scrollTo('roadmap')}>
                            Roadmap
                        </p>
                        <p className='font-1 mv-20 mobile-nav-item' onClick={() => this.props.scrollTo('plunge')}>
                            The Plunge
                        </p>
                        <p className='font-1 mv-20 mobile-nav-item' onClick={() => this.props.scrollTo('team')}>
                            Team
                        </p>
                        <Link to='/theplunge' style={{textDecoration: 'none', fontSize: 36}} className='font-1 mt-16'>
                            The Plunge
                        </Link>
                        <div className='row-ac mt-60'>
                            <div className='mr-40'>
                                <a href='https://www.instagram.com/seshworld.io/' target='_blank'>
                                    <Instagram/>
                                </a>
                            </div>
                            <div>
                                <a href='https://discord.gg/ZjYtqA5u' target='_blank'>
                                    <Discord/>
                                </a>
                            </div>
                        </div>
                    </div>
                </>

                }
            </div>
        )
    }
}

export default NavMenuMobile
