import React, {Component} from "react";
import sparkle from "../assets/img/Sparkle.png";
import splashlogo from "../assets/img/splashlogo.png";
import splashbgmobile from "../assets/img/splashbgmobilee.png";
import mobilesplashbreak from "../assets/img/mobilesplashbreak.png";
import MintButton from "../MintButton";


class SplashMobile extends Component {
    render() {
        return (
            <>
                <section className='splash-bg-mobile relative'
                         style={{paddingTop: 200, overflow: 'hidden'}}>
                    {/*<img src={sparkle} className='sparkle5'/>*/}
                    <div className='relative col-ac-jc w-100p' style={{zIndex: 1}}>
                        <div className='relative col-ac-jc'>
                            <img src={sparkle} className='sparkle1-mobile'/>
                            <img src={sparkle} className='sparkle2-mobile'/>
                            <img src={sparkle} className='sparkle3-mobile'/>
                            <img src={sparkle} className='sparkle4-mobile'/>
                            <img src={splashlogo} className='w-80p mb-100'
                                 style={{zIndex: 999, position: 'relative'}}/>
                        </div>
                        <div className='mb-64 relative col-ac mt-80' style={{marginTop: -50, zIndex: 11, height: 270, width:660}}>
                        <MintButton mobile={true}/>
                    </div>
                        <div className='font-1 splash-subtext-mobile mv-100 mt-80 relative w-100p'>
                            <img src={sparkle} className='sparkle5-mobile'/>
                            <img src={sparkle} className='sparkle6-mobile'/>
                            <img src={sparkle} className='sparkle7-mobile'/>
                            <img src={sparkle} className='sparkle8-mobile'/>
                            <img src={sparkle} className='sparkle9-mobile'/>
                            <img src={sparkle} className='sparkle10-mobile'/>
                            {/*<p>Minting Soon</p>*/}
                            {/*<p>*/}
                            {/*    0/10,000*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*    MINTED*/}
                            {/*</p>*/}
                        </div>
                        <div className='text-center mt-100 font-3 splash-subtext2-mobile relative'
                             style={{marginTop: 150}}>
                            <img src={sparkle} className='sparkle11-mobile'/>
                            <img src={sparkle} className='sparkle12-mobile'/>
                            <img src={sparkle} className='sparkle13-mobile'/>
                            <img src={sparkle} className='sparkle14-mobile'/>
                            <img src={sparkle} className='sparkle15-mobile'/>
                            <img src={sparkle} className='sparkle16-mobile'/>
                            <p style={{fontSize: 62}}>
                                2,222
                            </p>
                            <p>
                                Uniquely Degenerate<br/>Gators
                            </p>
                        </div>
                    </div>
                    <img src={splashbgmobile} className='w-100p mt-20 relative' style={{zIndex: 999}}/>
                </section>
                <div className='relative w-100p'>
                    <div className='page-break1'>
                        <img src={mobilesplashbreak} className='w-100p'/>
                    </div>
                </div>
            </>
        )
    }
}

export default SplashMobile
