import './styles/App.css';
import './styles/Formatting.css'
import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Main from "./pages/Main";
import ThePlunge from "./pages/ThePlunge";

class App extends Component {

    render() {
        window.Buffer = window.Buffer || require('buffer').Buffer;
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Main}/>
                    <Route exact path="/theplunge" component={ThePlunge}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
